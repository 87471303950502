<template>
    <v-footer class="justify-center" color="#292929" height="40">
        <div class="title font-weight-light grey--text text--lighten-1 text-center">&copy; {{ new Date().getFullYear() }} — Made with <v-icon color="red"> mdi-heart </v-icon> by GianPierree</div>
    </v-footer>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
};
</script>
