<template>
    <v-app>
        <Header />
        <router-view></router-view>
        <Footer />
    </v-app>
</template>

<script>
import Header from './views/Header.vue';
import Footer from './views/Footer.vue';

export default {
    name: "App",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            
        };
    },
};
</script>

<style scoped>

</style>
