<template>
    <div>
        <v-app-bar fixed color="#FEFEFE">
            <v-avatar color="lighten-5" size="70" style="cursor: pointer" @click="$vuetify.goTo('#home')">
                <v-img contain max-height="70%" src="@/assets/img/logo.png"></v-img>
            </v-avatar>
            <v-toolbar-title class="font-weight-black headline" style="font-family: 'Quicksand', sans-serif !important; cursor: pointer" @click="$vuetify.goTo('#home')"> GianPierree </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg">
                <v-btn-toggle id="nav" :borderless="true" v-model="format" v-for="(item, index) in items" :key="index">
                    <v-btn text plain class="align-self-end btn-header" active-class="btn-header-active" @click="$vuetify.goTo(item.src)" :value="item.value"> {<v-icon color="#000000" dense>{{ item.icon }}</v-icon> {{ item.name }} } </v-btn>
                </v-btn-toggle>
            </div>
            <!-- <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn text plain v-bind="attrs" v-on="on">
                    <v-icon color="#000000" dense>mdi-cog</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item>
                    <v-btn text plain> <v-icon color="#2c3e50" dense>mdi-theme-light-dark</v-icon> Dark mode </v-btn>
                </v-list-item>
                <v-list-item>
                    <v-btn text plain> <v-icon color="#2c3e50" dense>mdi-translate</v-icon> Language </v-btn>
                </v-list-item>
            </v-list>
        </v-menu> -->
            <v-app-bar-nav-icon v-else-if="$vuetify.breakpoint.md || $vuetify.breakpoint.sm || $vuetify.breakpoint.xs" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app temporary>
            <v-list dense>
                <v-list-item-group v-model="group" active-class="deep-red--text text--accent-4" v-for="(item, index) in items" :key="index">
                    <v-list-item @click="$vuetify.goTo(item.src)">
                        <v-list-item-title><v-icon color="#000000" dense>{{ item.icon }}</v-icon> {{ item.name }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: "Header",
    components: {},
    data() {
        return {
            format: "Home",
            drawer: false,
            group: null,
            items:[
                {
                    id: 1,
                    name: "Home",
                    value: "Home",
                    src: "#home",
                    icon: "mdi-home"
                },
                {
                    id: 2,
                    name: "About",
                    value: "About",
                    src: "#about-me",
                    icon: "mdi-heart"
                },
                {
                    id: 3,
                    name: "Skills",
                    value: "Skills",
                    src: "#skills",
                    icon: "mdi-xml"
                },
                {
                    id: 4,
                    name: "Contact",
                    value: "Contact",
                    src: "#contact",
                    icon: "mdi-coffee"
                },
            ]
        };
    },
    watch: {
        group() {
            this.drawer = false;
        },
    },
    methods: {
    
    }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

#nav {
    border: none !important;
    background: #fefefe !important;
}

.btn-header {
    font-family: "Quicksand", sans-serif !important;
    font-size: 1.1rem !important;
    font-weight: bold;
    color: #000000 !important;
    border-radius: 10px !important;
    border: none;
    padding: 10px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize !important;
}

.btn-header:hover {
    font-weight: bold;
    border-bottom: 5px solid #c40f0f !important;
}

#nav .btn-header-active {
    border-bottom: 5px solid #c40f0f !important;
    background: rgb(255, 255, 255, 0) !important;
}
</style>
